<script>
import { toRaw } from "vue";

  import {
    required,
    helpers
  } from "@vuelidate/validators";

  import useVuelidate from "@vuelidate/core";
  import { mapActions} from "vuex";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  
  import ordiniEffettuati  from "@/components/widgets/worksites/ordiniEffettuati";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'gestioneProdottiProgetto',
    props: [
      'type',
      'projectID',
      'worksiteID',
      'taskID',
      'interventionID',
      'data',
    ],
    setup() {
      moment.locale('it')
      let tk = 'Bearer '+localStorage.getItem('tk')
      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Gestione Prodotti progetto",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        addTabPlan:false,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
          name:"",
          details:"",
          ownership:false,
          ownershipDesc:'',
          manufacturer:'',
          manufacturerDesc:'',
          kmStart:'',
          createdBy:this.$store.state.auth.currentUser,
        },
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 10,
        sortRes:'asc',
        value: 'All',
        n_results:0,
        n_ordersResults:0,
        pages: [],
        responseList: [
        ],
        paginated:{},
        pageOrders: 1,
        perPageOrders: 10,
        responseOrdersList: [
        ],
        dbgruppo:'nd',
        select:'all',
        search:'',
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        resultsList:[],
        teamLeaderID:'',
        qSearch:'',
        dataEdit:{},
        minDate:moment().format('YYYY-MM-DDTHH:MM'),
        maxDate:moment().format('YYYY-MM-DD'),
        scheduleDelivery:{
          startStr:'',
          projectID:this.projectID,
          worksiteID:this.worksiteID, 
          endStr:"",
          name:"",
          details:"",
          dbgruppo:"",
        }
      };
    },
    components: {
      ordiniEffettuati
    },
    validations: {
      item: {
        name: {
          required: helpers.withMessage("Nome prodotto obbligatorio", required),
        },
        code: {
          required: helpers.withMessage("Codice identificativo prodotto obbligatorio", required),
        },
        manufacturer: {
          required: helpers.withMessage("Marca prodotto obbligatoria", required),
        },
        ownershipDesc: {
          required: helpers.withMessage("Indicazione proprietà prodotto obbligatoria", required),
        },
      },
      dataEdit: {
        name: {
          required: helpers.withMessage("Nome prodotto obbligatorio", required),
        },
        manufacturer: {
          required: helpers.withMessage("Marca prodotto obbligatoria", required),
        },
        ownershipDesc: {
          required: helpers.withMessage("Indicazione proprietà del prodotto obbligatoria", required),
        },
        number: {
          required: helpers.withMessage("Indicazione numero di pezzi utilizzati obbligatorio", required),
        },


      },

    },
    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      
    },

    methods: {
      ...mapActions('ecommercesettings', ['addItemToOrder', 'getCartByOrd']),
      convertDatetime(date){
        if (date){
          return  moment(date).format('DD/MM/YYYY HH:MM') 
        }
      },
      len(array){
        if(array){
          return array.length
        } else {
          return 0
        }
      },
      axiosInterceptor(){
        axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
      },
      resetDBGruppo(){
        this.qSearch=''
        this.resultsList = []  
      },
      async fullSearch(){
        if (this.dbgruppo!=='nd') {
          this.resultsList=[]
          this.axiosInterceptor()
          axios.get(`${this.UrlServer}intervention-reports/search/products/fulltext`,
              { 
                params: { 
                  dbgruppo:this.dbgruppo,
                  q: this.qSearch,
                },
                headers: { 'authorization':this.tk} 
              }
          )
          .then((response) => {
              if (response.data)  {
              this.resultsList = response.data
              }
          })
        } else {
         Swal.fire({  
           title: "Magazzino mancante", 
           text: "Attenzione: devi indicare il magazzino aziendale di riferimento ", 
           icon:"warning",              
           showCancelButton:false,
           showConfirmButton:false,
           timer:5000
         })
       }


      },
      getList(){
       
        let urlRef= `items-by-projectid/${this.$route.params.id}`
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}projects/${urlRef}`, 
          { 
            params: { 
              perPage: this.perPage,
              page: this.page,
              sortRes: this.sortRes,
              select: this.select,
              search: this.search,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          this.responseList = response.data.results.data;
          this.n_results = response.data.n_results;
          this.paginated = response.data.results
          this.getCarts()
          })
      },
      getCarts(){
       
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}carts/order/project/id/${this.$route.params.id}`, 
          { 
            params: { 
              perPage: this.perPageOrders,
              page: this.pageOrders,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          this.responseOrdersList = response.data.results.data;
          let rows = response.data.results.data.filter(function(el) {
            return el.doc_type == 'row'
          });
          let n = 0
          for (let i of rows) {
            if (i.detail_item.qta) {
              n+=i.detail_item.qta
            }
          }

          if (rows) {
            this.n_ordersResults = n
          } else {
            this.n_ordersResults = 0
          }
          
          this.paginatedOrders =  response.data.results
          this.checkItemsInOrder()
        
        })
      },
      checkItemsInOrder(){
        let self = this
        
        for (let item of this.responseOrdersList) {
          
          let items = self.responseList.filter(function(el){
            return el.CodArt == toRaw(item).gcode &&  toRaw(item).detail_item.qta==el.number
          }) 
          if(items){
            self.responseList.indexOf(toRaw(items[0]))
            toRaw(self.responseList[self.responseList.indexOf(toRaw(items[0]))]).inOrder = 1
          }
            
        }

      },
      prepProcOrder(){
        
        Swal.fire(
          {
            title: this.$t("t-processingorder-confirm-setting-title"),
            text: this.$t("t-processingorder-confirm-setting-text"),
            icon: this.$t("t-processingorder-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-processingorder-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-processingorder-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-processingorder-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-processingorder-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              this.processingOrder()
            }
        });
      },
      scheduleDel(){
       
       if (this.scheduleDelivery.startStr!='') {
         this.axiosInterceptor()
       
         if (this.scheduleDelivery.startStr!='')
         { 

          this.scheduleDelivery.task_type = 'task-worksite-delivery'
          this.scheduleDelivery.dateString = this.scheduleDelivery.startStr
          this.scheduleDelivery.dateTmp = moment(this.scheduleDelivery.startStr).unix()
          this.scheduleDelivery.year = moment().format('YYYY')
          this.scheduleDelivery.month = this.scheduleDelivery.dateString.split('-')[1]
          this.scheduleDelivery.day =  this.scheduleDelivery.dateString.split('-')[2]
          this.scheduleDelivery.start = moment(this.scheduleDelivery.startStr).valueOf()
          this.scheduleDelivery.start_tmp = moment(this.scheduleDelivery.startStr).unix()
          this.scheduleDelivery.end = moment(this.scheduleDelivery.startStr).valueOf()
          this.scheduleDelivery.end_tmp = moment(this.scheduleDelivery.startStr).unix()
          this.scheduleDelivery.createdBy = this.datastore.auth.currentUser
          this.scheduleDelivery.DBGruppo = this.data.DBGruppo
          
           axios.post(`${this.UrlServer}worksites/delivery/schedule`, this.scheduleDelivery, {headers: { authorization:this.tk}} )
             .then((response)=>{
              
               if (response.data) {
                   Swal.fire({  
                     title: this.$t("t-msg-delivery-init") , 
                     text: this.$t("t-msg-delivery-init-content"), 
                     icon:"success",              
                     showCancelButton:false,
                     showConfirmButton:false,
                     timer:5000
                     }).then(()=>{
                     
                      document.getElementById("closeScheduleModal").click(); 
                      this.processingWSOrder()
                     });
                }
             })
             
           }
       } else {
         Swal.fire({  
           title: "Dati mancanti", 
           text: "Attenzione: devi indicare la data di consegna in cantiere dei prodotti... ", 
           icon:"warning",              
           showCancelButton:false,
           showConfirmButton:false,
           timer:5000
         })
       }
     },
     processingWSOrder(){
      
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}carts/processing/worksite/order`, 
          {
            projectID: this.projectID
          }, 
          {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
        } )
          .then((response)=>{
            //const childComponent = this.$refs.getProcCarts;
            if (response.data) {
            
              const childComponent = this.$refs.getProcCarts;
              if (response.data) {
                if (childComponent) {
                  childComponent.getProcCarts();
                } else {
                    console.error('Child component reference is undefined.');
                }
                Swal.fire({  
                  title: this.$t("t-msg-processing-order-init") , 
                  text: this.$t("t-msg-processing-order-init-content"), 
                  icon:"success",              
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:3000
                })
              }
            }
        })
      },
      processingOrder(){
      
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}carts/processing-order`, 
          {
            projectID: this.projectID
          }, 
          {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
        } )
          .then((response)=>{
            //const childComponent = this.$refs.getProcCarts;
            if (response.data) {
              /*
              if (childComponent) {
                childComponent.getProcCarts();
              } else {
                  console.error('Child component reference is undefined.');
              }
                  */
              Swal.fire({  
                title: this.$t("t-msg-processing-order-init") , 
                text: this.$t("t-msg-processing-order-init-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              })
              if (response.data){
                if (response.data.cartID) {
                  this.sendOrderToERP(response.data.cartID)
                }
              }

            }
        })
      },
      sendOrderToERP(cartid){
        this.axiosInterceptor()
        if (this.data.customerDetails) {
          axios.post(`${this.UrlServer}orders/send-order-to-erp`, 
            {
              projectID: this.projectID,
              cartID: cartid,
              customerDetails: this.data.customerDetails,
            }, 
            {
              headers: { authorization:this.tk},
              onUploadProgress: (event) =>
              {
                const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                let timerInterval;
                Swal.fire({
                  title: "Caricamento...",
                  timer: percentUploaded,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();
                    const timer = Swal.getPopup().querySelector("b");
                    timerInterval = setInterval(() => {
                      timer.textContent = `${Swal.getTimerLeft()}`;
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  }
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    console.log('Caricamento...')
                  }
                });

              },
          } )
            .then((response)=>{
              const childComponent = this.$refs.getProcCarts;
              if (response.data) {
                if (childComponent) {
                  childComponent.getProcCarts();
                } else {
                    console.error('Child component reference is undefined.');
                }
                Swal.fire({  
                  title: this.$t("t-msg-processing-order-init") , 
                  text: this.$t("t-msg-processing-order-init-content"), 
                  icon:"success",              
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:3000
                })
              }
          })
        }

    },
      editdata(data) {
        this.dataEdit = data
      },
      
      initItem(){
        this.submitted = true;
        this.v$.$touch()
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}equipments/register`, this.item, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
        } )
          .then((response)=>{
            if (response.data) {
              Swal.fire({  
                title: this.$t("t-msg-vehicle-init") , 
                text: this.$t("t-msg-vehicle-init-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              }).then(()=>{
                  document.getElementById("closemodalEquip").click();
              });
            }
        })
      },
      addElement(params){
        let date = moment().format('YYYY/MM/DD')
        params.projectID = this.projectID
        params.productID = params._id
        
        params.area = 'project'
        params.year = date.split('/')[0]
        params.day = date.split('/')[2]
        params.month = date.split('/')[1]
        params.number = 1
        /*
        if ( moment().day() <10 ){
            params.day =`0${moment().day()}`
        } else {
            params.day = moment().month()
        }
        */
        params.date_tmp = moment().unix()
        params.createdBy = this.datastore.auth.currentUser
        if (params._id){
          delete params._id
        }

        this.axiosInterceptor()
        axios.post(`${this.UrlServer}intervention-reports/add-product`, params, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
             // let timerInterval;
              console.log(percentUploaded)
              /*
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
                
              }).then((result) => {
                
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });
              */

            },
        } )
          .then((response)=>{
            if (response.data) {
              this.getList()
              this.resultsList = []
              this.search=''
              Swal.fire({  
                title: this.$t("t-msg-element") , 
                text: this.$t("t-msg-element-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              })
            }
        })
      },
      deletedata(id){
        let self = this
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}intervention-reports/worksite-item/${id}`, {headers: { authorization:this.tk}} )
                .then(()=>{
                  self.getList() 
              })
            }
        });
      },
      deleteInOrder(id){
        let self = this
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}carts/item/${id}`, {headers: { authorization:this.tk}} )
                .then(()=>{
                  self.getList() 
              })
            }
        });
      },
      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(responseList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return responseList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      paginatedOrders(response) {
        let page = this.pageOrders;
        let perPage = this.perPageOrders;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return response.slice(from, to);
      },
      paginateOrdersAct(pg){
        this.pageOrders = pg
        this.getCarts()
      },
      splitStr(str,car){
            if (str){
            if(str.includes(car)==true) {
                let val =  str.split(car)
                return `${val[0]} ${val[1]}`
            } else{
                return str
            }
            } else {
            return ''
            }
      },
      selectItem(params){
          let array = this.responseList.filter(function(el){
            return el._id == params._id
          })
          if (array.length==0){
            this.addElement(params)
          } else {
            Swal.fire({
              title:"Prodotto già presente",
              text:"Prodotto che hai selezionato è già presente!",
              icon:"info",
              showCancelButton:false,
              showConfirmButton:false,
              timer:3000,
            }) 
          }
      },
      updatedata(){
          this.axiosInterceptor()
          axios.put(`${this.UrlServer}intervention-reports/worksite-item/${this.dataEdit._id}`, this.dataEdit, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
            } )
            .then((response)=>{
              if (response.data) {
                document.getElementById("editclosemodalEquip").click();
                this.getList()
              }
          })
      },
      getCart(){
        if ( localStorage.getItem("orx") ) {
          this.getCartByOrd(localStorage.getItem("orx"))
        }
      },
      addTC(gcode, codMarca, qty){
        moment.locale('it')
        let momentCode = moment().unix()
        let orx = false
      
        if ( localStorage.getItem("orxpro") ) {
          orx = localStorage.getItem("orxpro")
        } else {
          localStorage.setItem("orxpro", orx)
        }
        let sr = 'mov-magazzino'

        let data = { 
          productCode: gcode,
          userId: this.data.customerDetails._id,
          projectID: this.data._id,
          cuuid:momentCode,
          orx:orx,
          salesRoute:sr,
          qta: qty,
        }
        this.addItemToOrder(data)
        let self = this
        setTimeout(function(){
          self.getList()
        },2000)
      },
      editQtaCart(gcode,qty){

        let id 
        let order = this.responseOrdersList.filter(function(el){
          return el.gcode == gcode
        })
        
    
        if (order && order.length>0) {
          
          id = order[0]._id
          let data = { 
            detail_item:{
              idProduct: order[0].detail_item.idProduct,
              Nome: order[0].detail_item.Nome,
              Descrizione: order[0].detail_item.Descrizione,
              qta: qty,
              prezzoListino: order[0].detail_item.prezzoListino,
              prezzo: order[0].detail_item.prezzo,
              codIva: order[0].detail_item.codIva
            }
          }
          this.axiosInterceptor()
          axios.put(`${this.UrlServer}carts/${id}`,
            data,
            { 
              headers: { 'authorization':this.tk} 
            }
          )
          .then((response) => {
            
              if (response.data)  {
                this.getList()
              }
          })
        }

      }
    },
    mounted() {
      if (this.data) {
        if(!this.data.teamLeaderID){
          this.teamLeaderID = ''
        } else {
          this.teamLeaderID = this.data.teamLeaderID
        }
      }
      this.getList()
    }
  };
</script>

<template>
  <div class="back-grey mt-20">
    <div class="card">
        <div class="card-header">
            <h5 class="card-title mb-0">Gestione prodotti, componenti o materiale previsto</h5>
        </div>
        <div class="card-body">
          
    <div class="row ">
        <div class="col-lg-12 col-md-12">         
            <div class="card-body pl-10 mb-10">
                <template v-if="addTabPlan==false">
                    <button class="btn btn-warning bg-gradient" type="button" @click="addTabPlan=true">
                        <i class="bx bx-search"></i>
                        <i class="mdi mdi-ballot-outline"></i>
                       Ricerca Articolo
                    </button>  
                </template>
                <template v-else>
                    <button class="btn btn-light" @click="addTabPlan=false">
                        X
                        Chiudi ricerca Articolo
                    </button> 
                </template>
                <div style="float: right;">
                    <button type="button" class="btn btn-secondary" data-bs-toggle="modal" >
                      <a :href="`${UrlServer}docugen/download/product/project/${projectID}`" class="text-white">
                        <i class="ri-file-word-2-line"></i>  
                        Esporta Lista prodotti/materiali
                      </a>
                    </button>
                </div>
                
            </div>
        </div>
    </div>
    <div v-if="addTabPlan">
        <div class="row background-btn-grey-1  mb-20 mt-20 pl-10" >
          <div class="col-md-12 col-lg-2">
            <div class="mb-3 form-icon mt-20">
              <select class="form-select form-control-search p-3  mb-0.5 w-full border border-blue-300 rounded" v-model="dbgruppo" @change="resetDBGruppo">
                <option value="nd">Seleziona Magazzino Azienda</option>
                <option value="SP">Sunservice</option>
                <option value="ES">S.Energy Solutions</option>
              </select>
            </div>
          </div>
          <div class="col-md-12 col-lg-5">
               
                <div class="mb-3 form-icon mt-20">

                    <input
                        type="text"
                        id="search"
                        v-model="qSearch"
                        placeholder="Digita qui termine di ricerca del prodotto..."
                        class="form-control-search p-3 mt-26 mb-0.5 w-full border border-blue-300 rounded"
                        @input="fullSearch()"
                    >

                    <ul
                      v-if=" len(resultsList) > 0"
                      class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10"
                    >
                      <li class="px-1 pt-1 pb-2 font-bold border-b border-gray-200">
                                 {{ len(resultsList) }} 
                                <template v-if="len(resultsList)>1">
                                  risultati
                                </template>
                                <template v-else>
                                  risultato
                                </template>  
                      </li>
                      <li
                        v-for="item in resultsList"
                        :key="item._id"
                        @click="selectItem(item)"
                        class="cursor-pointer hover:bg-gray-100 p-1"
                      >
                        <strong>{{ item.Nome }}</strong> - Cod. Art.: <strong>{{ item.CodArt }}</strong>  <br> Marca: {{ item.CodMarca }} 
                      </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
      <div class="card-header  mb-20">
        <div class="row align-items-center mb-25">
          <div class="col ">
            <pre>{{ data.DBGruppo }}</pre>
            <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
              <li class="nav-item">
                <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                  Articoli
                  <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_results}}</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-auto">
            <div id="selection-element">
            </div>
          </div>
        </div>
      </div>
            <!-- end card header -->
      <div class="card-body">
        
        <div class="table-card mb-1">
          <div class="row thRow  ">
            <div class="col text-left">
              Descrizione
            </div>
            <div class="col text-left">
              Codice
            </div>
            <div class="col text-left">
              N. in progetto
            </div>
            <div class="col text-left">
              In ordine
            </div>
            <div class="col text-left">
              Giacenza Magazzino
            </div>
            <div class="col text-left">
              Azioni
            </div>
          </div>
          <div class="row list tRow form-check-all d-flex justify-content-end " v-for=" (item, index) of responseList" :key="index">
            <div class="col text-left">
              
              <strong>{{ item.Nome}}</strong>
            </div>
            <div class="col text-left">
              {{ item.CodArt }}
            </div>
            <div class="col text-left">
              {{ item.number }}
            </div>
            <div class="col text-left">
              <template v-if="item.inOrder">
                <template v-if="item.inOrder==1">
                  <span class="mdi mdi-24px mdi-check pr-5 "></span>
                </template>
                <template v-else>
                  <span class="mdi mdi-24px mdi-pause pr-5 "></span>
                </template>
              </template>
              <template v-else>
                <span class="mdi mdi-24px mdi-pause pr-5 "></span>
              </template>
            </div>
            <div class="col text-left">
              <template v-if="item.product">
                            <template v-if="item.product.length>0">
                              <template v-if="item.product[0].giacenzaEffettiva">
                                {{ item.product[0].giacenzaEffettiva }}
                              </template>
                            </template>
                        </template> 
                    </div>
                    <div class="col">
                        <ul class="list-inline text-left ml-30">
                          <li class="list-inline-item pr-10" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Rimuovi">
                              <a href="javascript:void(0)" class="text-danger d-inline-block remove-item-btn" @click="deletedata(item._id)">
                                <i class="ri-delete-bin-5-fill fs-16"></i>
                              </a>
                          </li>
                          <template v-if="item.product">
                            <template v-if="item.product.length>0">
                              <template v-if="item.product[0].Prezzo>0 ">
                                <template v-if="item.inOrder && item.inOrder!=1">
                                  <li class="list-inline-item pr-10" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Aggiungi all'ordine">
                                    <a href="javascript:void(0)" class="text-secondary d-inline-block remove-item-btn" @click="addTC(item.CodArt, item.CodMarca,item.number)">
                                      <i class=" bx bx-plus"></i><i class=" bx bx-cart"></i>
                                    </a>
                                  </li>
                                </template>
                                <template v-if="!item.inOrder">
                                  <li class="list-inline-item pr-10" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Aggiungi all'ordine">
                                    <a href="javascript:void(0)" class="text-secondary d-inline-block remove-item-btn" @click="addTC(item.CodArt, item.CodMarca,item.number)">
                                      <i class=" bx bx-plus"></i><i class=" bx bx-cart"></i>
                                    </a>
                                  </li>
                                </template>
                              </template>
                            </template>
                          </template>
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Modifica">
                              <a href="#editmodalProd" data-bs-toggle="modal"
                                class="text-primary d-inline-block edit-item-btn" @click="editdata(item)">
                                <i class="ri-pencil-fill fs-16"></i>
                              </a>
                            </li>

                        </ul>
                    </div>
                </div>
          </div>

            <div>
              <div class="table-responsive table-card mb-1">

                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                      :height="75" :width="75" />
                    <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                    <p class="text-muted mb-0">
                     
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex tRow justify-content-end mt-3" v-if="n_results > perPage">
                    <div class="col-lg-2 col-md-12 text-right">
                        <label class="pt-2">Risultati per pagina:</label>
                    </div>        
                    <div class="col-lg-1 col-md-12 ">
                        <div class="pl-10 pr-20">
                            <select class="form-select" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                                <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }" v-for="index in paginated.total_pages" :key="index">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                        
                        </ul>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
              </div>
        </div>
    </div>
   
          <div class="modal fade" id="staticBackdropEquip" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropEquipLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">                 
                  <div class="modal-header  bg-light p-3">
                      <h5 class="modal-title" id="varyingcontentModalLabel">Inserisci anagrafica prodotto</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form class="needs-validation" @submit.prevent="initItem">
                  <div class="modal-body">
                      <div class="mb-3">                
                        <label for="name" class="form-label">Denominazione prodotto <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" v-model="item.name" id="name" :class="{
                            'is-invalid': submitted && v$.item.name.$error,
                          }" placeholder="Inserisci denominazione prodotto">
                              <div v-for="(item, index) in v$.item.name.$errors" :key="index" class="invalid-feedback">
                                <span v-if="item.$message">{{ item.$message }}</span>
                              </div>
                      </div>
                      <div class="mb-3">                
                        <label for="name" class="form-label">Codice di serie prodotto <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" v-model="item.code" id="name" :class="{
                            'is-invalid': submitted && v$.item.code.$error,
                          }" placeholder="Inserisci denominazione prodotto">
                              <div v-for="(item, index) in v$.item.code.$errors" :key="index" class="invalid-feedback">
                                <span v-if="item.$message">{{ item.$message }}</span>
                              </div>
                      </div>
                      <div class="mb-3">                
                        <label for="manufacturer" class="form-label">Marca prodotto<span class="text-danger">*</span></label>
                          <input type="text" class="form-control" v-model="item.manufacturer" id="manufacturer" :class="{
                            'is-invalid': submitted && v$.item.manufacturer.$error,
                          }" placeholder="Inserisci marca prodotto">
                              <div v-for="(item, index) in v$.item.manufacturer.$errors" :key="index" class="invalid-feedback">
                                <span v-if="item.$message">{{ item.$message }}</span>
                              </div>
                      </div>
                      
                      <div class="mb-3">
                        <label for="details" class="form-label">Descrizione (facoltativa)</label>
                        <textarea class="form-control " id="details" placeholder="Descrizione" v-model="item.details"></textarea>
                        <div class="invalid-feedback">
                      
                        </div>
                      </div>

                        <div class="mb-3">
                          <select class="form-select" required aria-label="Proprietà del prodotto" v-model="item.ownershipDesc" :class="{
                            'is-invalid': submitted && v$.item.ownershipDesc.$error,
                          }">
                            <option value="">Seleziona Proprietà</option>
                            <option value="aziendale">Aziendale</option>
                            <option value="locazione">In locazione</option>
                            <option value="noleggio">A noleggio</option>
                          </select>
                            <div v-for="(item, index) in v$.item.ownershipDesc.$errors" :key="index" class="invalid-feedback">
                                <span v-if="item.$message">{{ item.$message }}</span>
                              </div>
                        </div>
                  </div>
                  <div class="modal-footer">
                    <div class="hstack gap-2 justify-content-end">
                      <button type="button" id="closemodalEquip" class="btn btn-light" data-bs-dismiss="modal">
                        Chiudi
                      </button>
                      <button type="submit" class="btn btn-info" >
                        <i class="ri-mail-send-fill me-2 align-bottom"></i>
                          Inserisci anagrafica
                      </button>
                    </div>
                  </div>
                </form>
              </div>
          </div>
          </div>
          <div class="modal fade" id="editmodalProd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropEquipLabel" aria-hidden="true">
                    <div class="modal-dialog  modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header bg-light p-3 pl-5 pr-5">
                          <h5 class="modal-title" id="exampleModalLabel">
                            Aggiorna prodotto in lista
                          </h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            id="close-modal" >
                          </button>
                        </div>
                        <form class="needs-validation" @submit.prevent="updatedata">
                          <div class="modal-body">
                            <div class="mb-3">                
                              <label for="Descrizione" class="form-label">Denominazione prodotto </label>
                              <div class="bold">
                                <h4>{{ dataEdit.Nome }}</h4> 
                              </div>
                            </div>
                            <div class="mb-3">                
                              <label for="CodArt" class="form-label">Codice prodotto </label>
                              <div  class="bold">
                                <template v-if="dataEdit.product && dataEdit.product.length>0">
                                  <h4 >{{ dataEdit.product[0].CodArt }}</h4> 
                                </template>
                              </div>
                            </div>
                            <div class="mb-3" v-if="dataEdit.product">                
                              <label for="CodArt" class="form-label">Produttore</label>
                              <div  class="bold">
                                <template v-if="dataEdit.product && dataEdit.product.length>0">
                                  <h4 >{{ dataEdit.product[0].CodMarca }}</h4>
                                </template>
                              </div>
                            </div>
                            <div class="mb-3">
                              <label for="details" class="form-label">Descrizione sulle modalità di utilizzo (facoltativa)</label>
                              <textarea class="form-control " id="details" placeholder="Descrizione" v-model="dataEdit.details"></textarea>
                              <div class="invalid-feedback">
                          
                              </div>
                            </div>

                              <div class="mb-3">
                                  <label for="number" class="form-label">Proprietà del prodotto<span class="text-danger">*</span></label>
                                  <div>
                                      <select class="form-select" required aria-label="Proprietà del prodotto" v-model="dataEdit.ownershipDesc" :class="{
                                          'is-invalid': submitted && v$.item.ownershipDesc.$error,
                                      }">
                                          <option value="">Seleziona Proprietà</option>
                                          <option value="aziendale">Aziendale</option>
                                          <option value="locazione">In locazione</option>
                                          <option value="noleggio">A noleggio</option>
                                      </select>
                                          <div v-for="(item, index) in v$.dataEdit.ownershipDesc.$errors" :key="index" class="invalid-feedback">
                                              <span v-if="item.$message">{{ item.$message }}</span>
                                          </div>
                                  </div>
                              </div>
                              <div class="mb-3">
                                <div class="row">
                                  <div class="col">
                                    <label for="number" class="form-label">Numero di pezzi previsti<span class="text-danger">*</span></label>
                                <input type="number" class="form-control" v-model="dataEdit.number" id="number" :class="{
                                  'is-invalid': submitted && v$.dataEdit.number.$error,
                                }" placeholder="Inserisci numero di pezzi previsti" @blur="editQtaCart(dataEdit.CodArt, dataEdit.number)" >
                                    <div v-for="(item, index) in v$.dataEdit.number.$errors" :key="index" class="invalid-feedback">
                                      <span v-if="item.$message">{{ item.$message }}</span>
                                    </div>
                                  </div>
                                  <div class="col">
                                      <label for="cost" class="form-label">Costo</label>
                                    <input type="number" class="form-control" v-model="dataEdit.cost" placeholder="Inserisci Costo">
                                  </div>
                                </div> 
                                <div class="row">
                                  
                                </div>          
                              </div>
                              <div class="mb-3 mt-50">
                                <label for="DescrizioneExtra1" class="form-label">Info Extra 1(facoltativa)</label>
                                <textarea class="form-control " id="DescrizioneExtra1" placeholder="Descrizione" v-model="dataEdit.DescrizioneExtra1"></textarea>
                                <div class="invalid-feedback">
                          
                                </div>
                              </div>
                              <div class="mb-3 mt-10">
                                <label for="DescrizioneExtra2" class="form-label">Info Extra 2(facoltativa)</label>
                                <textarea class="form-control " id="DescrizioneExtra2" placeholder="Descrizione" v-model="dataEdit.DescrizioneExtra2"></textarea>
                                <div class="invalid-feedback">
                          
                                </div>
                              </div>
                              <div class="mb-3 mt-10">
                                <label for="DescrizioneExtra3" class="form-label">Info Extra 3(facoltativa)</label>
                                <textarea class="form-control " id="DescrizioneExtra3" placeholder="Descrizione" v-model="dataEdit.DescrizioneExtra3"></textarea>
                                <div class="invalid-feedback">
                          
                                </div>
                              </div>
                        </div>
                          <div class="modal-footer">
                            <div class="hstack gap-2 justify-content-end">
                              <button type="button" id="editclosemodalEquip" class="btn btn-light" data-bs-dismiss="modal">
                                Chiudi
                              </button>
                              <button type="submit" class="btn btn-success">
                                Aggiorna
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
          </div>
      </div>
    </div>

  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card">
        <div class="card-header">
            <h5 class="card-title mb-0"> <i class="bx bx-cart fs-22"></i> Prodotti e materiali da consegnare in cantiere</h5>
        </div>
        <div class="card-body">
          <div class="card-header  mb-50">
            <div class="row align-items-center mb-25">
              <div class="col ">
                <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">

                  <li class="nav-item">
                    <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                     
                        In ordinazione 
                      <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_ordersResults}}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-auto">
                <template v-if="responseOrdersList">
                  <template v-if="responseOrdersList.length>0">
                 
                    <template v-if="data.DBGruppo">
                      <template v-if="data.DBGruppo!='nd'">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                          <i class="mdi mdi-truck"></i>  
                          Pianifica consegna in cantiere
                        </button>&nbsp;
                      </template>
                    </template>
                    <template v-else>
                      <b-alert show variant="danger">
                      <strong> Attenzione! </strong> Non è stato ancora indicato il magazzino di riferimento — Chiedi all'amministrazione
                      </b-alert>
                      <b-alert show variant="primary">
                        <strong> Magazzino azienda assente:</strong> Non è possibile pianificare la consegna
                      </b-alert>
                    </template>

                    
                    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-body text-center p-5">
                                    <div class="mt-4">
                                        <h4 class="mb-3">Pianifica consegna in cantiere</h4>
                                        <p class="text-muted mb-4"> Attraverso questo modulo potrai indicare all'addetto al magazzino quali prodotti/componenti dovranno essere preparati per la consegna in cantiere</p>
                                        <div class="row mb-50 mt-20 pl-10">
                                                  <div class="col-md-12 col-lg-6 justify-content-center">
                                                    <div  class="justify-content-center">
                                                      <label for="code-field" class="form-label"><strong>Data consegna</strong></label>
                                                      <div class="input-group mb-1">
                                                        <input  type="datetime-local" class="form-control" v-model="scheduleDelivery.startStr" :min="minDate"  placeholder="Inserisci data consegna"   />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div> 
                                              
                                        <div class="hstack gap-2 justify-content-center">
                                            <a href="javascript:void(0);" id="closeScheduleModal" class="btn btn-link link-danger shadow-none fw-medium" data-bs-dismiss="modal"><i class="ri-close-line me-1 align-middle"></i> Chiudi</a>
                                            <a href="javascript:void(0);" class="btn btn-primary" @click="scheduleDel()">Pianifica</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--
                    <button type="button" class="btn btn-primary pr-10 mr-10" data-bs-toggle="modal" >
                      <a href="javascript:void(0)" class="text-white" @click="prepProcOrder">
                        <i class="mdi mdi-warehouse"></i>  
                          Ordina prodotti/materiali in elenco
                      </a>
                    </button>
                  -->
                  </template>
                </template>

                <div id="selection-element">
                  
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-card mb-1">
              <div class="row thRow  ">
                <div class="col text-left">
                  Descrizione
                </div>
                <div class="col text-left">
                  Codice
                </div>
                <div class="col text-left">
                  Qta
                </div>
                <div class="col text-left">
                  Azioni
                </div>
              </div>
              <div class="row list tRow form-check-all d-flex justify-content-end " v-for=" (item, index) of responseOrdersList" :key="index" >
                <div class="col text-left" v-if="item.doc_type=='row'">
                  <strong>{{ item.detail_item.Nome}}</strong>
                  <br><i>{{ item.CodMarca }}</i>
                </div>
                <div class="col text-left" v-if="item.doc_type=='row'">
                  {{ item.gcode }}
                </div>
                <div class="col text-left" v-if="item.doc_type=='row'">
                  {{ item.detail_item.qta  }}
                </div>
                <div class="col" v-if="item.doc_type=='row'">
                  <ul class="list-inline text-left ml-30">
                    <li class="list-inline-item pr-10" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Rimuovi">
                      <a href="javascript:void(0)" class="text-danger d-inline-block remove-item-btn" @click="deleteInOrder(item._id)">
                        <i class="ri-delete-bin-5-fill fs-16"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
                <div>
                  <div class="table-responsive table-card mb-1">

                    <div class="noresult" style="display: none">
                      <div class="text-center">
                        <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                          :height="75" :width="75" />
                        <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                        <p class="text-muted mb-0">
                        
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex tRow justify-content-end mt-3" v-if="n_ordersResults > perPageOrders">
                        <div class="col-lg-2 col-md-12 text-right">
                            <label class="pt-2">Risultati per pagina:</label>
                        </div>        
                        <div class="col-lg-1 col-md-12 ">
                            <div class="pl-10 pr-20">
                                <select class="form-select" v-model="perPage" @change="paginateAct(paginatedOrders.pre_page)">
                                    <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 ">
                            <div class="pagination-wrap hstack gap-2">
                            <a class="page-item pagination-prev disabled" href="#" v-if="paginatedOrders.pre_page > 1" @click="paginateOrdersAct(paginated.pre_page)">
                                Precedenti
                            </a>
                            <ul class="pagination listjs-pagination mb-0" >
                                <li :class="{
                                        active: index == page,
                                        disabled: index == '...',
                                        }" v-for="index in paginatedOrders.total_pages" :key="index">
                                    <a class="page" href="#" @click="paginateOrdersAct(index)">{{ index}}</a>
                                </li>
                            
                            </ul>
                            <a class="page-item pagination-next" href="#" @click="paginateOrdersAct(paginatedOrders.next_page)" >
                            Successivi
                            </a>
                            </div>
                        </div>
                  </div>
            </div>
        </div>

        </div>
      </div>
    </div>

  </div> 
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <ordiniEffettuati :projectID="projectID" ref="getProcCarts" />
    </div>
  </div>
  </div>
</template>