<script>
import moment from 'moment'
import serverConfig from '@/helpers/config';
import { useCookies } from "vue3-cookies";
import Swal from "sweetalert2";
import axios from 'axios';


import documentManagerUT from "@/components/widgets/projects/documentManagerUT";

export default {
  name: "accountingManager",
  props: [
    'ID',
    'data',
  ],
  setup() {
   
    moment.locale('it')

    const { cookies } = useCookies();    
    let tk = 'Bearer '+cookies.get('tk')

    return { tk };
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        item:{
          accountingDetails:{
              contract:false,
              date:moment.unix(),
              dateStr:moment().format('YYYY-MM-DD'), 
              status: '', 
              response:'',
              note:'',
              createdBy:{}, 
          },
          tipoImpiantoFatturato:false,
          fatturazioneAvviata:false,
          fatturazioneAvviataStatus:false,         
        },
        famList:[],
        productCode:'',
        orders:[],
        optionsModPayConfigList:[],
    };
  },
    validations: {
    },
  mounted() {
    
    this.item = this.data
    if(!this.data.prezzoTotale){
     
      this.item.prezzoTotale=0
      
    }
    this.getModPayConfigList()
    this.getOrders()
    this.getCodFAM()

  },

  methods: {
    axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }                
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
    },
    convertDate(val){
        this.item.accountingDetails.date =  moment(val).unix()
        this.updateItem()
    },
    getCodFAM(){
      let tk = localStorage.getItem('tk')
      if (this.data) {
        axios.get(`${this.UrlServer}categories/filter/product/desc/IMPIANTI-${this.data.systemSubCategory}`,  
        {headers: { authorization:`Bearer ${tk}`}} 
        ).then((response)=>{
            if (response.data){
              this.famList = response.data
          } 
        })   
      }
    },
    sendOrder(){
      this.axiosInterceptor()
      axios.put(`${this.UrlServer}projects/${this.ID}`, this.item, {headers: { authorization:this.tk}} )
        .then(()=>{
          alert('Ordine inviato!')
      })
    },
    updateItem(){
      
      this.axiosInterceptor()
      axios.put(`${this.UrlServer}projects/${this.ID}`, this.item, {headers: { authorization:this.tk}} )
          .then(()=>{
            
          })
    },
    preSendOrder() {
        Swal.fire(
          {
            title: this.$t("t-confirm-sendorder-title"),
            text: this.$t("t-confirm-sendorder-text"),
            icon: this.$t("t-confirm-sendorder-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-confirm-sendorder-cancel-button-text"),
            cancelButtonColor: this.$t("t-confirm-sendorder-cancelButtonColor"),
            confirmButtonColor: this.$t("t-confirm-sendorder-confirmButtonColor"),
            confirmButtonText: this.$t("t-confirm-sendorder-confirmButtonText")
          }
        ).then((result) => {
          if (result.isConfirmed) {
            this.processOrder()
          }
        });
    },
    processOrder(){

        if (this.item) {
          axios.post(`${this.UrlServer}orders/process-system-order`,this.item, 
            { 
              headers: { 'authorization':this.tk},
              onUploadProgress: (event) =>
                {
                  const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                
                  let timerInterval;
                  Swal.fire({
                    title: "Caricamento...",
                    html: "Progresso operazione <b></b>.",
                    timer: percentUploaded,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();

                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    }
                  }).then((result) => {
                    
                    if (result.dismiss === Swal.DismissReason.timer) {
                      Swal.fire({
                        title:"Inizio operazione",
                        text:"Avvio creazione ordine",
                      
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:3000,
                      })
                    }
                  });

                },
                onDownloadProgress: (event) =>
                {
                  const percentDownloaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                
                  let timerInterval;
                  Swal.fire({
                    title: "Download data",
                    timer: percentDownloaded,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();

                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    }
                  }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.timer) {
                      Swal.fire({
                        title:"Esito operazione",
                        text:"Ordine in lavorazione...",
                        icon:"success",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:5000,
                      })
                    }
                  });

                }
            },
            
          ).then( response => {
              if (response.data) {
                this.getOrders()
              }
            
          })

        }

    },
    sendOrderToErp(data){
      if (data) {
        axios.post(`${this.UrlServer}orders/send-system-order-to-erp`,data, 
          { 
            headers: { 'authorization':this.tk},
            onUploadProgress: (event) =>
              {
                const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              
                let timerInterval;
                Swal.fire({
                  title: "Caricamento...",
                  html: "Progresso operazione <b></b>.",
                  timer: percentUploaded,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();

                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  }
                }).then((result) => {
                  
                  if (result.dismiss === Swal.DismissReason.timer) {
                    Swal.fire({
                      title:"Inizio operazione",
                      text:"Avvio creazione ordine",
                    
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                  }
                });

              },
              onDownloadProgress: (event) =>
              {
                const percentDownloaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              
                let timerInterval;
                Swal.fire({
                  title: "Download data",
                  timer: percentDownloaded,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();

                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  }
                }).then((result) => {
                  if (result.dismiss === Swal.DismissReason.timer) {
                    Swal.fire({
                      title:"Esito operazione",
                      text:"Ordine in lavorazione...",
                      icon:"success",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                  }
                });

              }
          },
          
        ).then( response => {
            if (response.data) {
              alert('Ordine inviato ad ESOLVER')
              console.log(response.data)
              Swal.fire({
                title:"Esito invio ordine",
                text:"L'Ordine è stato inviato al sistema ESolver",
                icon:"success",
                showCancelButton:false,
                showConfirmButton:false,
                timer:4000,
              })

            }
          
        })

      }
    },
    deletedata(id) {
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
        ).then((result) => {
          if (result.isConfirmed) {
            alert(id)
            try {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}orders/orderprocessed/${id}`,{headers: { 'authorization':this.tk}})
                .then(() => {
                  this.getOrders()
                  Swal.fire(this.$t("t-msg-deleted") , this.$t("t-msg-data-deleted"), "success");
                })
            }catch(error){
            
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
          }
        });
    },
    getModPayConfigList() {
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}esync/get-mod-pay-config`, 
        {headers: { authorization:this.tk}} )
        .then((response) => {
            if (response) {
              if (response.data.status=='data') {
                this.optionsModPayConfigList = response.data.results
                this.optionsModPayConfigList.sort(function (a, b) {
                  if (a.Des < b.Des) {
                    return -1;
                  }
                  if (a.Des > b.Des) {
                    return 1;
                  }
                  return 0;
                });

              } else {
                Swal.fire({
                    title: "Errore di configurazione",
                    text: "Non è possibile ottenere i dati di configurazione delle modalità di pagamento provenienti dal gestionale.",
                    icon: 'error',
                    timer:2000,
                  })
              }
            }
            
        })
    },

    getOrders(){
      let tk = localStorage.getItem('tk')
      if (this.item) {
        axios.post(`${this.UrlServer}orders/get/ordersprocessed/byproject`, this.item, 
        {headers: { authorization:`Bearer ${tk}`}} 
        ).then((response)=>{
            if (response.data){
              this.orders = response.data
          } 
        })   
      }
    },
    deteleOrder(id) {
      let tk = localStorage.getItem('tk')
        axios.delete(`${this.UrlServer}orders/orderprocessed/${id}`,
        {headers: { authorization:`Bearer ${tk}`}} 
        ).then((response)=>{
            if (response.data){
              this.getOrders()
          } 
        })   
    }
  },
  components: {
    documentManagerUT,
  },

};
</script>

<template>
    <form action="">

        <div class="row mb-1 back-grey">
            <div class="col-lg-2 col-md-12">
             
                <label for="contractInput" class="form-label">Stipula contratto</label>
                <select class="form-select"  placeholder="Seleziona stato stipula contratto" data-trigger id="contractInput" v-model="item.accountingDetails.contract"  @change="updateItem">
                    <option value="">Seleziona stato Stipula contratto</option>
                    <option value="false">Non avvenuta</option>
                    <option value="true">Avvenuta</option>
                   
                </select>                      
            </div>
            <div class="col-lg-2 col-md-12">
                <label for="dateRegInput" class="form-label">Data stipula contratto</label>
                <input type="date" class="form-control" id="dateRegInput" v-model="item.accountingDetails.dateStr"  @change="convertDate(item.accountingDetails.dateStr)">
            </div>
            <div class="col-lg-2 col-md-12">
              <div class="mb-3">
                <label for="CodCondizPagamento" class="form-label">Modalità di pagamento</label>
               
                <template v-if="item.customer">
                  <template v-if="item.customer.length>0">
                    <select class="form-select" v-model="item.customer[0].CodCondizPagamento" >
                    <template v-if="optionsModPayConfigList.length>0">
                      <option  v-for="item in optionsModPayConfigList"  :value="item.CodCondizPagamento" :key="item"> {{ item.Des }}</option>
                    </template>
                  </select>
                  </template>
                </template>
              </div>  
            </div>      
            <div class="col-lg-3 col-md-12">

            </div>
        </div>
        <div class="row mb-3 back-grey">
            <div class="col-lg-6">
                <label for="responseMSGInput" class="form-label">Note di registrazione (facoltativo)</label>
                  <textarea class="form-control" id="responseMSGInput" rows="3" placeholder="Inserisci eventuali note sul so" v-model="item.accountingDetails.note" @blur="updateItem"></textarea>                       
            </div>
           
            <div class="col-lg-3">
                                    
            </div>      
            <div class="col-lg-3">
              </div>
        </div>
        <div class="row mb-3 back-grey">
            <div class="col-lg-12">
                <label  class="form-label"><h4>Ordine e Fatturazione</h4></label>
                 
            </div>
        </div>
          <div class="row mb-3 back-grey">
            <div class="col-lg-12">
                <label  class="form-label">Tipologia Impianto</label>:
                <b-badge variant="primary" class="badge-label" ><i class="mdi mdi-circle-medium"></i> <strong>{{ item.systemSubCategory }}</strong></b-badge>
            </div>
          </div>
          <div class="row mb-3 back-grey">
            <div class="col-lg-3  col-md-12">
                <label  class="form-label">Seleziona Caratteristiche</label>:
                  <select class="form-select"  placeholder="Seleziona impianto da fatturare" data-trigger id="billInput" v-model="item.tipoImpiantoFatturato"  @change="updateItem" >
                    <option value="">Seleziona impianto da fatturare</option>
                    <option  v-for="item in famList" :key="item._id" :value="item.CodArt">{{ item.Nome }}</option>
                </select>    
              </div>
              <div class="col-lg-2  col-md-12">
                <div class="mb-3">
                  <label for="email" class="form-label">Prezzo Impianto  <span class="text-danger">*</span></label>
                    <div class="input-group mb-3">
                      <input type="number" class="form-control" v-model="item.prezzoTotale" id="prezzoTotale"  placeholder="Prezzo Impianto" @blur="updateItem" />
                      <div class="input-group-append"><span class="input-group-text">&euro;</span></div>
                  </div> 
                </div>    
              </div>
              <div class="col-lg-2  col-md-12">
                <label for="contractInput" class="form-label">Azienda di riferimento</label>
                <select class="form-select"  placeholder="Seleziona stato stipula contratto" data-trigger id="contractInput" v-model="item.DBGruppo"  @change="updateItem">
                    <option value="">Seleziona Azienda</option>
                    <option value="SP">Sunservice</option>
                    <option value="ES">S.Energy Solutions</option>
                </select> 
              </div>
              <div class="col-lg-2  col-md-12">
                <template v-if="item.prezzoTotale>0 && item.DBGruppo!=''">
                  <label for="responseMSGInput" class="form-label">Conferma ordine</label>:
                  <br>
                  <button type="button" class="btn btn-primary custom-toggle active" data-bs-toggle="button" @click="preSendOrder()">
                    <span class="icon-on"><i class="ri-bookmark-line align-bottom"></i></span>
                    <span class="icon-off"><i class="ri-bookmark-3-fill align-bottom"></i></span>
                    Conferma
                  </button>
                </template>
               
              </div>
              <div class="col-lg-3  col-md-12">
                
              </div>
            </div> 
            <div class="row pl-3 pr-3 mb-5 back-grey">
              <div class="col-lg-12">
                  <label  class="form-label"><strong>Ordini pronti per essere fatturati </strong></label>:
                  <b-badge variant="secondary" class="badge-label" > <strong>ESolver</strong></b-badge>
              </div>
          </div>
          <div class="row pl-3 pr-3 mb-5 back-grey">
              <div class="col-lg-8 col-md-12 mb-50">
                  <div class="row bold back-dark-grey">
                    <div class="col-lg-2 col-md-12">Ordine</div>
                    <div class="col-lg-2 col-md-12">Azienda</div>
                    <div class="col-lg-2 col-md-12">Data</div>
                    <div class="col-lg-2 col-md-12">Prezzo</div>
                    <div class="col-lg-2 col-md-12">Stato</div>
                    <div class="col-lg-2 col-md-12">Azione</div>
                  </div>
                  <div class="row mt-20 back-white border-grey" v-for="item in orders" :key="item._id">
                    <div class="col-lg-2 col-md-12">{{ item.orderCode }}</div>
                    <div class="col-lg-2 col-md-12">{{ item.DBGruppo }}</div>
                    <div class="col-lg-2 col-md-12">{{ item.day }}/{{ item.month }}/{{ item.year }}</div>
                    <div class="col-lg-2 col-md-12">{{ item.prezzoTotale }}</div>
                    <div class="col-lg-2 col-md-12">
                      <template v-if="item.order_sd_status!='done'">
                        In attesa di fatturazione
                      </template>
                    </div>
                    <div class="col-lg-2 col-md-12">
                      <ul class="list-inline hstack gap-2 mb-0">
                        <template v-if="!item.syncES || item.order_sd_status!='done'">
                          <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Rimuovi">
                            <a href="javascript:void(0)"  class="text-danger d-inline-block remove-item-btn" @click="deletedata(item._id)">
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a>
                          </li>
                        </template>

                          <li class="list-inline-item edit" title="Invio ad ESolver per fatturazione">
                            <a href="javascript:void(0)" @click="sendOrderToErp(item)" >
                              <i class="mdi mdi-format-float-none fs-16"></i>
                            </a>
                          </li>
                    </ul>
                    </div>
                  </div>
              </div>
          </div>
    </form>
    <div class="row mb-3">
        <div class="col-lg-12">
          <template v-if="data">
              <template v-if="data.customer!=null">
                <template v-if="data.customer.length>0">
                 
                  <documentManagerUT 
                    :url="`${this.UrlServer}storage/by/project`"
                    :projectID="data._id" 
                    :data="data" 
                    :bucket="data.customer[0].nomeFascicolo + data.year.toString() + '/' + data.systemSubCategory+'/'" 
                    type="doc-raccolta-dati"
                    refDirectory="raccolta_dati"
                    fileType="multiple" 
                    fileAccept="*" 
                    title="Documentazione impianto"
                    v-on:getParent="getParent()"/>
                </template>
              </template>
            </template> 


        </div>
    </div>




</template>
